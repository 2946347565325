
.interactive-manage {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 100px);
    .interactive-content {
        display: flex;
        flex-direction: column;
        height: 1%;
        flex: 1;
        .content-top {
            display: flex;
            align-items: center;
            width: 100%;
            height: 68px;
            background-color: #fff;
            margin-bottom:20px;
            .student-name {
                color: #333;
                font-size: 24px;
                margin: 0 50px 0 40px;
                font-weight: 500;
            }
            .class-name {
                color: #333;
                font-size: 24px;
                font-weight: 500;
            }
            .task-works_-tate {
                color: #333;
                font-size: 16px;
            }
        }
        .content-details{
          display: flex;
          flex-direction: column;
          background: #fff;
          padding: 20px 40px;
          span{
            color: #333;
            &:nth-child(1){
              font-size: 18px;
              line-height: 1;
            }
            &:nth-child(2){
              font-size: 16px;
              display: inline-block;
              width: 100%;
              padding: 16px 20px;
              background: #F8F7FF;
              border-radius: 4px;
              line-height: 24px;
              margin-top: 20px;
            }
          }
        }
        .content-module{
          display: flex;
          flex-direction: column;
          .module-title{
            display: flex;
            align-items: center;
            line-height: 1;
            .module-title-line{
              width: 6px;
              height: 10px;
              background: #574DED;
              border-radius: 3px;
              margin-right: 10px;
            }
            .module-title-name{
              color: #333333;
              font-size: 18px;
            }
          }
          .module-content{
            margin-top: 10px;
            background: #fff;
            display: flex;
          }
          .module-top{
            margin-top: 20px;
          }
          .module-script{
            .module-content{
              padding: 30px 46px 40px;
              flex-wrap: wrap;
              justify-content: space-around;
              .script-item{
                width:358px;
                margin-top: 10px;
                &:hover {
                  cursor: pointer;
                }
                .item-cover {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    width: 100%;
                  }
                }
                .annex-info {
                  border: 1px solid #DDDDDD;
                  height: 80px;
                  span {
                    display: block;
                    height: 40px;
                    line-height: 40px;
                    width: 100%;
                    padding: 0 12px;
                    box-sizing: border-box;
                  }
                  .title {
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    color: #333;
                    font-size: 16px;
                  }
                  .bottom {
                    display: flex;
                    justify-content: space-between;
                    color: #666666;
                    font-size: 14px;
                  }
                }
              }
            }
          }
          .module-teletext{
            .module-content{
              flex-wrap: wrap;
              padding: 0 76px 0 90px;
              .teletext-item{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 150px;
                margin: 40px 60px 40px 0;
                .item-cover {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  width: 100px;
                  height: 100px;
                  img {
                    max-height: 100%;
                    max-width: 100%;
                  }
                }
                .title {
                  margin-top: 20px;
                  line-height: 1;
                  display: block;
                  width: 100%;
                  overflow: hidden;
                  text-overflow:ellipsis;
                  white-space: nowrap;
                  color: #444444;
                  font-size: 14px;
                }
                &:hover {
                  .title {
                    cursor: pointer;
                    color: #66b1ff;
                  }
                }
              }
            }
          }
          .module-video{
            .module-content{
              padding: 70px 0;
              flex-direction: column;
              align-items: center;
              .video-item{
                width: 970px;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 60px;
                &:nth-child(1){
                  margin-top: 0;
                }
                .item-cover {
                  width: 100%;
                  line-height: 1;
                  video{
                    width: 100%;
                  }
                }
                .title {
                  margin-top: 26px;
                  color: #333333;
                  font-size: 24px;
                  line-height: 1;
                }
              }
            }
          }
          .module-analyse{
            .module-content{
              flex-wrap: wrap;
              padding: 0px 0 66px;
              .analyse-item{
                margin-top: 60px;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: calc(100% / 7);
                text-align: center;
                img{
                  max-width: 66px;
                  max-height: 76px;
                }
                span{
                  line-height: 1;
                  margin-top: 24px;
                  width: 90%;
                  font-size: 16px;
                  color: #333333;
                  cursor: pointer;
                }
              }
            }
          }
          .module-download{
            .module-content{
              flex-direction: column;
              padding: 54px 0 40px;
              .download-item{
                display: flex;
                justify-content: space-between;
                height: 50px;
                background: #F8F7FF;
                margin-top: 10px;
                padding: 0 190px 0 130px;
                .download-item-left{
                  display: flex;
                  align-items: center;
                  .img-box{
                    width: 30px;
                    height: 35px;
                    img{
                      max-width: 100%;
                      max-height: 100%;
                    }
                  }
                  span{
                    margin-left: 16px;
                    color: #333333;
                    font-size: 16px;
                  }
                }
                .download-item-right{
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  span{
                    color: #333333;
                    font-size: 14px;
                  }

                }
              }
              .download-all-btn{
                display: flex;
                justify-content: flex-end;
                .el-button{
                  margin: 50px 40px 0 0;
                  padding: 10px 20px;
                  span{
                    font-size: 16px;
                    margin-left: 8px;
                  }
                }
              }
            }
            ::v-deep .el-checkbox{
              .el-checkbox__label{
                display: none;
              }
              &.is-checked{
                .is-checked{
                  .el-checkbox__inner{
                    background-color: #9ACC5B;
                    border-color: #9ACC5B;
                  }
                }
              }
            }
          }
          .module-link{
            .module-content{
              display: flex;
              flex-direction: column;
              padding: 54px 0 128px;
              .link-item{
                padding: 0 40px 0 348px;
                line-height: 1;
                height: 50px;
                display: flex;
                align-items: center;
                background: #F8F7FF;
                margin-top: 10px;
                span{
                  display: block;
                  font-size: 16px;
                  color: #333;
                }
                a{
                  display: block;
                  font-size: 16px;
                  flex: 1;
                  width: 1%;
                  color: #574DED;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .content-mid{
          margin:20px 0;
          display: flex;
          flex-direction: column;
          background: #fff;
          padding: 56px 30px 30px 40px;
          .content-mid-top, .content-mid-mid, .content-mid-qualified{
            display: flex;
            &.content-mid-mid{
              margin-top: 30px;
              .teacher-score{
                span{
                  margin-left: 10px;
                }
                ::v-deep.el-rate .el-rate__item{
                  .el-rate__icon{
                    font-size: 24px;
                    margin-right: 0;
                  }
                }
              }
            }
            span{
              margin-right: 20px;
              color: #333;
              font-size: 18px;
            }
            &.content-mid-qualified{
              margin-top: 40px;
              align-items: center;
              .el-button{
                font-size: 18px;
                padding: 10px 24px;
              }
            }
            .btn-green-item{
              color: #fff !important;
              background: #9ACC5B;
              border-color: #9ACC5B;
              &:hover{
                background: #5ACC5B;
                border-color: #20C997;
              }
            }
            .btn-red-item{
              color: #fff !important;
              background: #FF6668;
              border-color: #FF6668;
              &:hover{
                background: #dc0012;
                color: #fff;
                border-color: #fc0014;
              }
            }
          }
          .content-mid-bottom{
            display: flex;
            justify-content: flex-end;
            padding-top: 36px;
            .el-button{
              font-size: 18px;
              padding: 10px 34px;
            }
          }
        }
        //.content-bottom {
        //    flex: 1;
        //    width: 100%;
        //    height: 1%;
        //    background-color: #fff;
        //    padding: 10px 40px 15px;
        //    box-sizing: border-box;
        //    .header-tab {
        //        display: flex;
        //        justify-content: center;
        //        align-items: center;
        //        height: 50px;
        //        border-bottom: 1px solid #EEEEEE;
        //        .tab-item {
        //            display: flex;
        //            flex-direction: column;
        //            position: relative;
        //            font-size: 16px;
        //            margin-right: 102px;
        //            cursor: pointer;
        //            border-bottom: 2px solid transparent;
        //            &:last-child {
        //                margin-right: 0;
        //            }
        //            .annex-name {
        //                display: block;
        //                min-width: 40px;
        //                text-align: center;
        //            }
        //            .annex-line {
        //                display: block;
        //                min-width: 40px;
        //                height: 2px;
        //                background-color: transparent;
        //                position: relative;
        //                top: 14px;
        //            }
        //        }
        //        .current {
        //            /*border-bottom: 2px solid #9ACC5B;*/
        //            .annex-line {
        //                background-color: #9ACC5B;
        //            }
        //            &:before {
        //                position: absolute;
        //                top: 23px;
        //                left: 13px;
        //                content: '';
        //                width: 0;
        //                height: 0;
        //                border-style: dashed dashed solid dashed;
        //                border-width: 7px;
        //                border-color: transparent transparent #9ACC5B transparent;
        //            }
        //            &:nth-child(4) {
        //                &:before {
        //                    left: 25px;
        //                }
        //            }
        //        }
        //    }
        //    .annex-content {
        //        padding: 40px 0;
        //        .content-item {
        //            .annex-list {
        //                display: flex;
        //                flex-wrap: wrap;
        //                justify-content: space-between;
        //                &:after{
        //                  content: '';
        //                  width: 392px;
        //                }
        //                .annex-item {
        //                    margin-top: 10px;
        //                    width: 392px;
        //                    border: 1px solid #DDDDDD;
        //                    &:hover {
        //                        cursor: pointer;
        //                        border: 1px solid #66b1ff;
        //                    }
        //                    .item-cover {
        //                        width: 100%;
        //                        display: flex;
        //                        align-items: center;
        //                        justify-content: center;
        //                        img {
        //                            width: 100%;
        //                        }
        //                    }
        //                    .annex-info {
        //                        height: 80px;
        //                        span {
        //                            display: block;
        //                            height: 40px;
        //                            line-height: 40px;
        //                            width: 100%;
        //                            padding: 0 10px;
        //                            box-sizing: border-box;
        //                        }
        //                        .title {
        //                            overflow: hidden;
        //                            text-overflow:ellipsis;
        //                            white-space: nowrap;
        //                            color: #333;
        //                            font-size: 16px;
        //                        }
        //                        .bottom {
        //                            display: flex;
        //                            justify-content: space-between;
        //                            color: #666666;
        //                            font-size: 14px;
        //                        }
        //                    }
        //                }
        //            }
        //        }
        //      .analyse-list{
        //        display: flex;
        //        flex-wrap: wrap;
        //        .analyse-item{
        //          margin-top: 10px;
        //          display: flex;
        //          flex-direction: column;
        //          align-items: center;
        //          width: calc(100% / 8);
        //          text-align: center;
        //          img{
        //            max-width: 76px;
        //            max-height: 86px;
        //          }
        //          span{
        //            line-height: 1;
        //            margin-top: 20px;
        //            width: 100%;
        //            font-size: 18px;
        //            color: #333333;
        //            cursor: pointer;
        //          }
        //        }
        //      }
        //    }
        //}
    }
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .blue-btn {
        padding: 10px 36px;
        margin-bottom: 20px;
    }
}

::v-deep.annex-list-dialog {
  border-radius: 6px;
  .el-dialog__header{
    display: none;
  }
    ::v-deep .el-dialog__body {
      padding: 30px;
    }
}

.view-script-main{
    display: flex;
    flex-direction: column;
    height: 100%;
  //::v-deep .el-scrollbar__wrap {
  //  overflow-x: hidden;
  //}
    .view-script-item-top{
        display: flex;
        //align-items: center;
        .view-script-img{
            width:390px;
            height:224px;
            margin-right:30px;
            img{
                max-width:390px;
                max-height:224px;
                border-radius: 10px;
            }
        }
        .view-script-right{
            flex: 1;
            width: 1%;
            .view-script-name{
                margin-top: 10px;
                font-size: 18px;
                color: #333;
            }
            .view-script-fenlei{
                font-size: 16px;
                color: #666;
                margin-top: 30px;
            }
            .view-script-jianjie{
                font-size: 16px;
                color: #666;
                margin-top: 8px;
                line-height: 30px;
            }
        }
    }
    .view-script-body{
        display: flex;
        flex-direction: column;
        border: 2px solid #584EEE;
        //box-shadow: 0px 10px 0px 0px #ECEBFF;
        border-radius:20px;
        margin:48px 0 30px;
        .view-script-body-title{
            width: 160px;
            height: 32px;
            background: #584EEE;
            border-radius: 16px;
            line-height: 32px;
            text-align: center;
            color: #fff;
            margin: -12px 0 0 24px;
            position: relative;
            &:before{
                content: "";
                background: #fff;
                -webkit-border-radius: 50%;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 12px;
                left: 20px
            }
            &:after{
                content: "";
                background: #fff;
                -webkit-border-radius: 50%;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 12px;
                right: 20px
            }
        }
        .view-script-body-content{
            display: flex;
            min-height: 100px;
            padding-left: 22px;
        }
    }

    ::v-deep.el-table{
        .warning-row {
            background: #ECEBFF;
        }
    }
}
::v-deep .el-dialog__footer {
    text-align: center;
    padding: 10px 20px;
    .dialog-footer {
        .el-button {
            padding: 10px 36px;
            margin-bottom: 20px;
        }
        .el-button--primary {
            background-color: #2338E6;
            border: 1px solid #2338E6;
            &:hover {
                background-color: #1b2dbf;
                border: 1px solid #1b2dbf;
            }
        }
    }
}
.graphic-list {
    display: flex;
    flex-wrap: wrap;
    .graphic-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 130px;
        margin: 0 20px 20px 0;
        .item-cover {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 100px;
            img {
                max-height: 100%;
                max-width: 100%;
            }
        }
        .title {
            display: block;
            width: 100%;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            color: #444444;
            font-size: 14px;
        }
        &:hover {
            .title {
                cursor: pointer;
                color: #66b1ff;
            }
        }
    }
}
.video-list {
    display: flex;
    flex-wrap: wrap;
    .video-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc((100% - 40px)/3);
        height: 280px;
        margin: 0 20px 20px 0;
        &:nth-child(3n) {
            margin-right: 0;
        }
        .item-cover {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 250px;
            video {
                height: 100%;
                width: 100%;
            }
        }
        .title {
            display: block;
            width: 100%;
            height: 30px;
            line-height: 30px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            color: #333;
            font-size: 16px;
        }
    }
}
.link-list {
    .link-item {
        width: 100%;
        height: 50px;
        background-color: #F8F7FF;
        line-height: 50px;
        /*text-align: center;*/
        margin-bottom: 10px;
        display: flex;
        padding-left: 20px;
        .annex-name {
            margin-right: 20px;
        }
        .clip-checkbox {
            height: 100%;
            .img-item {
                width: 100%;
                text-align: left;
                padding: 0 20px;
                display: flex;
                align-items: center;
                height: 100%;
                ::v-deep .el-checkbox__label {
                    flex: 1;
                    width: 1%;
                    display: flex;
                }
            }
        }
    }
}
.download-btn {
    float: right;
    padding: 8px 34px;
    background-color: #2338E6;
    border: 1px solid #2338E6;
    &:hover {
        background-color: #1b2dbf;
        border: 1px solid #1b2dbf;
    }
}
.script-dialog-table{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  height: 265px;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .dialog-table-row{
    display: flex;
    &:nth-child(odd){
      background: #DEDCFD;
    }
    &:nth-child(even){
      background: #F8F7FF;
    }
    &:nth-child(1){
      .dialog-table-column{
        font-size: 14px;
        color: #222222;
        font-weight: 400;
      }
    }
    .dialog-table-column{
      display: flex;
      flex: 1;
      width: 1%;
      justify-content: center;
      align-items: center;
      height: 50px;
    }
  }
}
.download{
  cursor: pointer;
  &:hover{
    color: #66b1ff;
  }
}
